@import url(../node_modules/font-awesome/css/font-awesome.css);
@import url(./css/layout.css);
@import url(./css/modal.css);
@import url(./css/form.css);


.option-table-options {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 15px;
  margin: 5px;
}

.option-table-options p {
  margin: 10px;
  text-decoration: underline;
  color: gray;
  cursor: pointer;
}

.option-table-data {
  border: 2px solid #ddd;
  border-radius: 10px;
  margin: 5px;
}

.option-table-data * p {
  margin: 10px;
  text-decoration: underline;
  color: gray;
  cursor: pointer;
}

.circle-shape {
  clip-path: circle(60px);
}

.bordered {
  border: 1px solid #ddd;
  border-radius: 12px;
  border-collapse: unset;
  margin-right: 2px;
}

.bordered > p {
  margin: none;
}


.hovericon {
  cursor: pointer;
}

.hovericon:hover {
  color: #3c8dbc;
}

.sidebar {
  position: fixed;
  width: 260px;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  background: #222d32;
  color: #b8c7ce;
  font-size: 14px;
  transition: 0.5s;
  overflow-x: hidden;
}

.main-section {
  margin-left: 300px;
  height: 100vh;
  transition: margin-left 0.5s;
}

.toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  right: 12px;
  bottom: 12px;
}


.login-form,
.Form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 20px;
}

.Form>input,
select,
option,
.form-input {
  padding: 10px;
  min-width: 300px;
  margin-left: 10px;
  outline: none;
  border: 1px solid gray;
}

.form-input {
  text-decoration: underline gray;
}

.form-input>label {
  padding-right: 25px;
}

.Form>label {
  font-weight: bold;
  padding-top: 20px;
  margin: 5px;
}

.submit-btn {
  padding: 10px;
  margin: 10px;
  background-color: #3c8dbc;
  border: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.media-table {
  width: 100%;
  border-collapse: collapse;
}

.media-thead {
  background-color: #1976d2;
  color: white;
}

.center-div {
  display: grid;
  justify-self: center;
}

button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: #1976d2;
  color: antiquewhite;
  margin: 5px;
  cursor: pointer;
}

.formInput {
  margin: 10px;
}

.formInput label {
  display: inline-block;
  text-align: left;
  width: 7vw;
  min-width: 4vw;
  margin: auto 10px auto 0;
  font-weight: bold;
  font-size: 12px;
}

.formInput input {
  font-size: 12px;
  padding: 10px;
  min-width: 12.5vw;
  /* border: 1px solid #ddd; */
  border-radius: 6px;
  border: 1px solid gray;
  color: #5d5d5d;
  transition: 0.1s;
}