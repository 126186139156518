.Form {
  overflow-x: auto;
}

.indented-form {
  padding: 10px;
  margin: 10px;
}

.indented-form caption {
  width: 100px;
  text-align: left;
  margin-bottom: 10px;
  color: gray;
}

.Form .formInput, .indented-form .formInput {
  margin: 10px;
}

.indented-form div .formInput {
  margin: 5px;
}

.icon-input {
  display: flex;
  margin: 10px;
}

.Form .formInput label,
.icon-input label {
  display: inline-block;
  text-align: left;
  width: 7vw;
  min-width: 4vw;
  margin: auto 10px auto 0;
  font-weight: bold;
  font-size: 12px;
}

.Form .formInput input,
.Form .formInput .input,
.multi-select-options .options-controller input {
  font-size: 12px;
  padding: 10px;
  min-width: 12.5vw;
  border: 1px solid #ddd;
  border-radius: 6px;
  color: #5d5d5d;
  transition: 0.1s;
}

.formInput textarea {
  height: 50px;
  flex-grow: 1;
  /* border: none; */
  resize: none;
  padding: 10px;
}

textarea {
  border: 1px solid #ddd;
  border-radius: 6px;
  color: #5d5d5d;
}

.Form .formInput input:read-only {
  /* border-bottom: none; */
  background-color: #f6f6f6;
}

.Form .formActions {
  text-align: left;
  padding: 10px;
}

.Form button,
.Form .button {
  background: #3c8dbc;
  color: #fff;
  border-style: none;
  padding: 9px 16px;
  margin: 4px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
}

.Form button:active:hover,
.Form .button:active:hover,
.button:active:hover {
  background-color: rgba(60, 141, 188, 0.95);
}

.button-disabled {
  background: #898b8b;
  color: #fff;
  border-style: none;
  padding: 9px 16px;
  margin: 4px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.Form button:hover,
.Form .button:hover {
  color: #fff;
  background-color: rgba(60, 141, 188, 0.8);
  border-color: #269abc;
  text-decoration: none;
}

.multi-select-input {
  border: 1px #ddd solid;
  padding-left: 10px;
  min-width: 13vw;
  border-radius: 6px;
  display: inline-flex;
  cursor: pointer;
  height: 35px;
  font-size: 13px;
}

.multi-select-input-small {
  min-width: 100px;
  border: 1px #ddd solid;
  padding-left: 10px;
  border-radius: 6px;
  display: inline-flex;
  cursor: pointer;
  height: 35px;
  font-size: 13px;
}

.multi-select-input>div,
.multi-select-input-small>div {
  flex-grow: 1;
  line-height: 2.5;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multi-select-input>i,
.multi-select-input-small>i {
  line-height: 2;
  padding-right: 10px;
}

.multi-select-options {
  position: absolute;
  background-color: white;
  border: gray;
  transform: translateY(4px);
  max-width: 350px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 5000;
  font-size: 13px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-radius: 12px;
}

.multi-select-options .options-controller {
  margin: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.multi-select-options .options-controller>i:nth-child(2) {
  margin-left: 4px;
}
.multi-select-options .options-controller>i {
  border-radius: 6px;
  margin: 0;
  /* margin-left: 10px; */
  /* margin-right: -5px; */
}

.multi-select-options ul {
  padding: 5px 0;
  margin: 10px 0;
  max-height: 300px;
  overflow-y: auto;
}

.multi-select-options ul li {
  list-style: none;
  padding: 5px 20px;
  cursor: pointer;
}

.multi-select-options ul li:hover,
.multi-select-options ul li:focus,
.multi-select-item-create:hover {
  background-color: #e2e2e2;
}

.multi-select-options ul li>i {
  margin-right: 5px;
}

.multi-select-item-create {
  margin-top: -10px;
  padding: 8px 20px;
  color: #3c8dbc;
}

select {
  height: 25px;
  font-size: 12px;
}

.button {
  background: #3c8dbc;
  color: #fff;
  border-style: none;
  padding: 9px 16px;
  border-radius: 6px;
  margin: 4px;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon-button {
  height: 18px;
  width: 18px;
  padding: 8px;
}

.button>i {
  font-size: 16px;
  margin-left: -5px;
  color: white;
}

.icon-button>i{
  margin-left: 0;
}

.button-inactive {
  border: 1px solid #ddd !important;
  background: white !important;
  color: black !important;
}

.button-inactive>i {
  color: grey !important;
}

.button-inactive:hover {
  background-color: #e8e8e8 !important;
}

.button-inactive:active:hover {
  background-color: #cccbcb !important;
}

.button:hover {
  color: #fff;
  background-color: rgba(60, 141, 188, 0.8);
  /* border-color: #269abc; */
  text-decoration: none;
}

button.small {
  font-size: 13px;
  width: 140px;
}

.button.small {
  font-size: 13px;
  width: 124px;
  text-align: center;
}

[type=checkbox] {
  height: 20px !important;
}

.tab-button-left {
  width: 75px !important;
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.tab-button-center {
  width: 75px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.tab-button-right {
  width: 75px !important;
  margin-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.tab-button-left>i,
.tab-button-center>i,
.tab-button-right>i {
  margin-right: 5px;
}

.Form .rangeInput input {
  min-width: 4.5vw;
  width: 4.5vw;
}
.Form .rangeInput span {
  margin: auto 10px;
}

.observation-edit-options {
  width: fit-content;
  margin-top: 10px;
  padding: 10px;
}

.observation-edit-options caption {
  width: 100px;
  text-align: left;
  margin-bottom: 10px;
  color: gray;
}

.observation-edit-options .formInput {
  margin: 0;
}

.cronInput, .formInput .cronInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px
}

.cronInput .formInput {
  margin: 0;
}

.cronInput label, .formInput .cronInput label {
  width: 80px;
  margin: 0 10px 0 0;
  color: rgb(93, 93, 93);
  font-size: 13px;
  font-weight: normal;
}

.cronInput span, .formInput .cronInput span {
  margin-left: 10px;
  color: rgb(93, 93, 93);
  font-size: 13px;
}

@media (max-width: 900px) {

  .Form .formInput {
    display: flex;
    flex-direction: row;
    width: calc(100vw - 80px);
  }

  .indented-form .Form .formInput, .indented-form .formInput, .modalChildren .Form .formInput {
    width: calc(100vw - 126px);
  }

  .Form .formInput label,
  .icon-input label {
    width: 20vw;
    min-width: unset;
  }

  .Form .formInput .formInputMulti,
  .Form .formInput input,
  .Form .formInput .input {
    flex-grow: 1;
    width: unset;
    min-width: unset;
  }

  .multi-select-input {
    width: calc(100% - 10px);
    min-width: unset;
  }

  .Form .rangeInput input {
    width: 5vw;
    min-width: unset;
    /* width: 50px; */
  }

  .observation-edit-options .formInput {
    width: 100%;
  }

}