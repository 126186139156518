.data-table-container{
	width: 90%;
	margin: 5px auto;
}

.data-table-container h1{
	color: rgb(60, 141, 188);
}

.data-table {
	width: 100%;
	border-collapse: collapse;
	border-radius: 10px;
	white-space: nowrap;
	box-shadow: 0px 10px 10px #ccc;
	overflow: hidden;
}

.data-table thead {
	background-color: rgb(60, 141, 188);
	color: rgb(255, 255, 255);
	text-align: left;
}

.data-table th,
.data-table td {
	padding: 0.8rem;
	min-width: 0;
	overflow: hidden;
	color: rgb(116, 116, 116);
}

.data-table th {
	color: rgb(255, 255, 255);
}

.data-table td {
	border-top: 0.5px solid #ddd;
	white-space: normal;
}

.data-table tbody tr:hover {
	background-color: rgb(237, 237, 237);
}

.action-icons>i {
	color: white;
	margin-left: 5px;
}

.data-table tbody tr:hover .action-icons>i {
	color: grey;
}